<template>
  <div class="terms mx-5">
    <hero-alt />
    <base-section id="theme-features">
    <base-section-heading title="Terminos">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col>
          <div class="content">
            
            <h1>Términos y Condiciones</h1>
            <p>
                Los siguientes términos representan un acuerdo legal entre el cliente y <strong>Monitor Satelital</strong>. 
                Al realizar una orden, aceptar la entrega de un producto, acceder, navegar y/o utilizar este sitio monitorsatelital.com.mx, Usted está de acuerdo que ha leído, entendido y acordado, el regirse por estos términos y cumplir con todas las regulaciones listadas en el sitio y leyes aplicables. Usted y Monitor Satelital acuerdan que los siguientes términos y condiciones son los únicos términos que rigen las transacciones de ventas entre cliente y Monitor Satelital. 
                Si usted no está de acuerdo con estos términos, no utilice este sitio. 
                Cualquier intento de alterar, suprimir o modificar estos términos y condiciones por el cliente se considerarán una alteración material a este acuerdo y, por consecuencia, serán nulos e inválidos. Cualquier reclamo relacionado al uso de este sitio y los materiales contenidos se regirán por los términos del sitio y a las leyes aplicables del Estado de Nuevo León.
            </p>
            <p>
                Todos los pagos recibidos a través de sistema de PayPal o con Tarjeta de Débito o Crédito, están sujetos a verificación de datos. 
                Es posible que se le soliciten algunos de estos documentos posteriormente a realizar su pedido: Copia de identificación oficial, Comprobante de domicilio.
                <br>
                Cualquier pago recibido con Tarjeta de Crédito o Débito podrá ser cancelado o reintegrado si no se puede verificar la identidad del usuario y/o cliente y/o por así determinarlo monitor satelital. No se pagarán intereses sobre devoluciones o reintegros bajo ninguna circunstancia.
            </p>
            <p>
                Estos términos y condiciones están sujetos a cambios en cualquier momento, sin previo aviso, escrito o de cualquier otra forma. En consecuencia por favor revise estos términos y condiciones cuidadosamente cada vez que Usted coloque una orden o al aceptar la entrega de los productos de monitor satelital o cualquier otro representante o mediador que le entregue el producto.
            </p>

        </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>


  <base-business-info>
    
  </base-business-info>

  </div>
</template>
<script>

export default {
  components: {
    HeroAlt: () => import('./sections/HeroAlt'),
  },
  metaInfo: { title: 'Terminos' },
  name: "Terminos",
}
</script>
<style>
</style>

